/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */

import React, { useState, useEffect, useRef, useContext } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import Quill from '../Quill/quill-lynit'

import debounce from 'lodash/debounce'

import { sharedContext } from '../../state/sharedProvider'
import {
	setCacheConnDesc,
	setCacheConnSelection,
	setFieldError,
	setNewConnId,
	setNewElement,
	setNoElementError,
	setServerError,
} from '../../state/actions'
import { toastHandler } from '../../utils/backendHandler'
import Toast from '../CustomToast'
import { deleteCacheElement, deleteCacheConnections, updateCacheField } from '../../utils/apollo'
import { QuillContainer } from './styles'
import { useGetRelationshipsQuery, useUpdateNodeMutation, useUpdateRelationshipMutation } from '../../hooks'
import useQuillAutoSave from '../AutoSave/useQuillAutoSave'
import { userStateContext } from '../../state/userProvider'
import { beatsDataDispatchContext, beatsDataStateContext } from '../../state/beatsProvider'
import { systemStateContext } from '../../state/systemProvider'
import { nodeTypeForId } from '../../utils/utils'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { getAiRecommendationFromText } from '../../utils/lynitCoachAnalytics'
import { IDENTIFY_DRIVERS } from '../../data'

const Delta = Quill.import('delta')

const DescriptionField = ({
	id,
	fieldName,
	type,
	placeholder,
	isRightPanel,
	isChapterTab,
	isFocusedQuill,
	setAiRecommendations = () => {},
	aiRecommendations,
	updatedAt = () => new Date().toISOString(),
	performHighlight = () => {},
	isDroupdownOpen,
	aiRecommendated
}) => {

	if (!isRightPanel) {
		// eslint-disable-next-line no-param-reassign
		isRightPanel = false
	}
	const {
		state: { searchResult },
		dispatch: dispatchAction,
	} = useContext(sharedContext)
	const client = useApolloClient()
	let change = new Delta()
	const serverErrorInterval = useRef()

	const [updateElement] = useUpdateNodeMutation(type, { ignoreResults: true })
	const [updateNote] = useUpdateNodeMutation('Note', { ignoreResults: true })
	const [performUpdateRelationshipMutation] = useUpdateRelationshipMutation({ ignoreResults: true })
	const [isFocused, setFocused] = useState(false)

	const beatsRelatedData = useContext(beatsDataStateContext)
	const systemRelatedData = useContext(systemStateContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const user = useContext(userStateContext)
	const graphData = useContext(graphDataStateContext)
	const { data: relationships } = useGetRelationshipsQuery({fetchPolicy: 'cache-only', component:"DescriptionField"})
	const [identifyDrivers] = useMutation(IDENTIFY_DRIVERS)
	const [lastRecommendationText, setLastRecommendationText] = useState('')
	const elementDescription = nodeTypeForId(id) === "Relationship" ? relationships?.relations?.find(rel => rel.id === id)?.[fieldName] : graphData?.nodes?.[id]?.[fieldName]
	
	let startTime, endTime
	const update = (id, description, text) => {
		if (id) {
			if (type === 'Note') {
				updateNote({
					variables: {
						id,
						input: {
							contents: description,
							updatedAt: updatedAt(),
						},
					},
				})
					.then(res => {
						change = new Delta()
						if (res && beatsRelatedData?.serverError) {
							beatsDataDispatch(setServerError(false))
							clearInterval(serverErrorInterval.current)
						}
						beatsDataDispatch(setFieldError(false))
					})
					.catch(async err => {
						quill?.blur()
						if (err.message.includes('Cannot updated')) {
							beatsDataDispatch(setNoElementError(true))
							setTimeout(
								() =>
									deleteCacheElement(client, { id, type }).then(() => {
										beatsDataDispatch(setNoElementError(false))
									}),
								3000,
							)
						} else if (
							err.message.includes('Failed to fetch') ||
							err.message.includes('NetworkError')
						) {
							beatsDataDispatch(setServerError(true))
						} else if (err.message.includes('Response not successful:')) {
							beatsDataDispatch(setFieldError(true))
						} else {
							toastHandler('error', 'There was an error updating a note, try again.')
							systemRelatedData?.createLog(
								`ToastHandler Error Message`,
								`{"errorMessage":"${err.message}"}`,
								'DescriptionField',
								'Note Updation',
							)
						}
					})
			}
			if (type !== 'Note' && id?.includes('rel')) {
				performUpdateRelationshipMutation({
					id,
					input: {
						description,
					},
					client,
					serverErrorInterval,
					newMapValue: { description, updatedAt: new Date().toISOString() },
				})
					.then(res => {
						change = new Delta()
					})
					.catch(async err => {
						quill?.blur()
					})
			}
			let input = { updatedAt: updatedAt() }
			input[fieldName] = description
			if (type !== 'Note' && !id?.includes('rel')) {

				if ((text.length >= 50)  && aiRecommendated && lastRecommendationText !== text) {
					//startTime = performance.now()


					debounceFn(id,fieldName,text)
					
					

					if((!aiRecommendations.loading)){
						delayedRecLoading()
					}

					
					
					
					
					
				}
				if(text.length <50 && aiRecommendated ){
					setAiRecommendations({drivers:[]})
				}

				setLastRecommendationText(text)

				updateElement({
					variables: {
						id,
						input,
					},
				})
					.then(res => {
						change = new Delta()
						if (res && beatsRelatedData?.serverError) {
							beatsDataDispatch(setServerError(false))
							clearInterval(serverErrorInterval.current)
						}
						beatsDataDispatch(setFieldError(false))
					})
					.catch(async err => {
						quill?.blur()
						if (err.message.includes('Cannot update')) {
							beatsDataDispatch(setNoElementError(true))
							setTimeout(
								() =>
									deleteCacheElement(client, { id, type }).then(() => {
										beatsDataDispatch(setNoElementError(false))
									}),
								3000,
							)
						} else if (
							err.message.includes('Failed to fetch') ||
							err.message.includes('NetworkError')
						) {
							beatsDataDispatch(setServerError(true))
						} else if (err.message.includes('Response not successful:')) {
							beatsDataDispatch(setFieldError(true))
						} else {
							toastHandler('error', 'There was an error updating an element, try again.')
							systemRelatedData?.createLog(
								`ToastHandler Error Message`,
								`{"errorMessage":"${err.message}"}`,
								'DescriptionField',
								'Element Updation',
							)
						}
					})
			}
		} else {
		}
	}

	const { quill, quillRef } = useQuillAutoSave(
		{
			modules: {
				toolbar: false,
			},
			placeholder,
		},
		update,
		id,
		fieldName,
		elementDescription,
	)

	useEffect(() => {
		if (quill?.root) {
			quill.root.dataset.placeholder = placeholder || ''
		}
	}, [quillRef, placeholder])
	
	useEffect(()=>{
		setTimeout(() => {
			if(quill && isFocusedQuill){
				quill.focus()
				dispatchAction(setNewElement({
					name:null
				}))
			}
		}, 100);
	},[quill,isFocusedQuill])

	useEffect(() => {
		window.onbeforeunload = () => {
			if (change?.length() > 0) {
				return 'There are unsaved changes. Are you sure you want to leave?'
			}
		}
	}, [isFocused])

	useEffect(() => {
		if (quill) {
			if (beatsRelatedData?.serverError || beatsRelatedData?.noElementError) {
				quill.disable()
			} else {
				quill.enable()
			}
			if (!user?.user?.subscriptionStatus?.editDescriptionfield) {
				quill.disable()
			}
		}
	}, [
		beatsRelatedData?.serverError,
		beatsRelatedData?.noElementError,
		quill,
		user?.user?.subscriptionStatus?.editDescriptionfield,
	])

	useEffect(() => {
		if (beatsRelatedData?.serverError) {
			serverErrorInterval.current = setInterval(() => {
				if (change?.length() > 0) {
					update(id, JSON.stringify(quill.getContents()))
				}
			}, 500)
		}
		if (!beatsRelatedData?.serverError) {
			clearInterval(serverErrorInterval.current)
		}
	}, [beatsRelatedData?.serverError])

	useEffect(() => {
		if (beatsRelatedData?.fieldError) {
			setTimeout(() => {
				toastHandler(
					'dark',
					'',
					<Toast
						onRetry={() => {
							//update(id,JSON.stringify(quill.getContents()))
							setError(false)
						}}
						isRetry
						content={'Content not update. Try again later.'}
					/>,
				)
			}, 1000)
		}
	}, [beatsRelatedData?.fieldError])
	
	const delayedRecLoading = debounce(() => {

		setAiRecommendations({loading: true})
	},2000, {maxWait:10000})

	const debounceFn = debounce(
		async (driverId,fieldName, fieldText) => {
			let aiRecommendationData
			
			identifyDrivers({
				variables:{
					driverId
					,fieldName
					,fieldText
					,elementType: nodeTypeForId(driverId)
					,elementName: graphData?.nodes[driverId].name || nodeTypeForId(driverId)
					,fieldPlaceholder: placeholder
				}
			}).then((data)=>{

				aiRecommendationData = JSON.parse(data?.data.identifyDrivers ||`{"drivers":[]}`)
				//console.log("aiRecommendationData",aiRecommendationData)
				aiRecommendationData.loading = false
				setAiRecommendations(aiRecommendationData)
				//endTime = performance.now()
				//console.log(`Call to identifyDrivers took ${endTime - startTime} milliseconds`)

			}).catch((e) => {
				setAiRecommendations({loading:false})

			})
			
		},
		2000,

		
	)
	
	useEffect(() => {
		//I need to load the recommendations from teh backend and display them.

		if ((quill?.getText().length > 1)  && aiRecommendated && lastRecommendationText !== quill?.getText()) {
			debounceFn(id,fieldName,quill?.getText())
			setLastRecommendationText(quill?.getText())
			
		}
		if(quill?.getText().length === 1 && aiRecommendated ){
			setAiRecommendations({drivers:[]})
			//setLastRecommendationText(quill?.getText())
		}
	}, [quill])

	// useEffect(() => {
	// 	if (quill && !isDroupdownOpen && aiRecommendated) performHighlight(quillRef, quill)
	// }, [quill, aiRecommendations,isDroupdownOpen])

	return (
		<>
			<QuillContainer
				data-testid="quill"
				data-private="lipsum"
				data-dd-privacy="mask"
				isEnabled={user?.user?.subscriptionStatus?.editDescriptionfield}
				type={type}
				isChapterTab={isChapterTab}
				isRightPanel={isRightPanel}
				ref={quillRef}
				id={id}
				data-fieldname={fieldName}
				translate="no"
				// onBlur={() => {
				// 	if(aiRecommendated){
				// 		quill.disable()
				// 		performHighlight(quillRef, quill)
				// 		setTimeout(() => {
				// 			quill.enable()
				// 		}, 2000)
				// 	}
				// }}
				// onFocus={() => {
				// 	setFocused(true)
				// 	const highlights = quillRef.current.querySelectorAll(
				// 		'span[style*="background-color: yellow"]',
				// 	)
				// 	highlights.forEach(highlightedElement => {
				// 		const parentElement = highlightedElement.parentNode
				// 		// Replace the highlighted span with its text content
				// 		parentElement.replaceChild(
				// 			document.createTextNode(highlightedElement.textContent),
				// 			highlightedElement,
				// 		)
				// 		// Merge text nodes to prevent fragmenting
				// 		parentElement.normalize()
				// 	})
				// }}
			/>
		</>
	)
}
export default React.memo(DescriptionField)
