import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { graphDataStateContext } from './graphDataProvider'
import { useGetRelationshipsQuery, useLazyGetRelationshipsQuery } from '../hooks'
import { orderBy } from 'lodash'

export const analyticsDataStateContext = createContext()
export const analyticsDataDispatchContext = createContext()

const initialAnalyticsData = {
	missingPremiseRelationships: [],
	premiseDriverList: [],
	premiseRingCompletionPct: 0,
	missingMilestoneBeatsCount: 7,
	driversByDesignation: {total: [],  premise:[], secondary:[], minor:[]},

	
}

export const setAnalyticsData = payload => {
	return {
		type: 'SET_ANALYTICS_DATA',
		payload,
	}
}

export const resetANALYTICSData = () => {
	return {
		type: 'RESET_ANALYTICS_DATA',
	}
}

export const analyticsDataReducer = (state, action) => {
	switch (action.type) {
		case 'SET_ANALYTICS_DATA':
			return action.payload 
		case 'RESET_ANALYTICS_DATA':
			return initialAnalyticsData
		default:
			return state
	}
}

export const AnalyticsDataProvider = ({ children }) => {
	const [state, dispatch] = useReducer(analyticsDataReducer, initialAnalyticsData)
	const [missingPremiseRelationships, setMissingPremiseRelationships] = useState([])
	const [premiseDriverList, setPremiseDriverList] = useState([])
	const [driversByDesignation, setDriversByDesignation] = useState({ total: [], premise:[], secondary:[], minor:[]})

	const [premiseRingCompletionPct, setPremiseRingCompletionPct] = useState(0)
	const [missingMilestoneBeatsCount, setMissingMilestoneBeatsCount] = useState(7)
	const [driversWithABeat, setDriversWithBeat] = useState([])
	const [secondaryDriversWithMileStoneBeat, setSecondaryDriversWithMileStoneBeat] = useState([])
	const [relationshipsWithABeat, setRelationshipsWithABeat] = useState([])
	const [minorDriversWithRelationship, setMinorDriversWithRelationship] = useState([])
	const [beatsByDriverType, setBeatsByDriverType] =  useState({withDriver: [], noDriver: [], character:[],arc:[], setting:[], theme: [], event: []})
	const [milestonBeatsByDriverType, setMilestoneBeatsByDriverType] =  useState({withDriver: [], noDriver: [], character:[],arc:[], setting:[], theme: [], event: []})

	const [relationshipsByType, setRelationshipsByType] = useState({static:[], premise:[], beat:[], note:[], driver:[]}) 

	const [milestoneBeatsCount, setMilestoneBeatsCount ] =  useState(0)
	const graphData = useContext(graphDataStateContext)
	const { data: relationships, loading: loadingRelationship } = useGetRelationshipsQuery({fetchPolicy: 'cache-only', component:"analyticsDataProvider"})
	

	useEffect(() => {
		if(graphData?.nodes && relationships?.relations){
			const tempDriversByDesignation = {total:[],premise:[], secondary:[], minor:[]}
			const temp_driversWithABeat = []
			const temp_secondaryDriversWithMileStoneBeat = []
			const temp_relationshipsWithABeat = []
			const temp_minorDriversWithRelationship = []
			const temp_reltionshipsByType = {static:[], premise:[], beat:[], note:[], driver:[]}
			const temp_beatsByDriverType = {withDriver: [], noDriver: [], character:[],arc:[], setting:[], theme: [], event: []}
			const temp_milestonBeatsByDriverType = {withDriver: [], noDriver: [], character:[],arc:[], setting:[], theme: [], event: []}
			Object.values(graphData?.nodes || []).forEach(
				node => {
					if( node.__typename === 'Beat'){
						node.driverConnections?.forEach(conn => {
							const driverTypeName = (conn.sourceNode.__typename === "Beat" ? conn.destNode.__typename : conn.sourceNode.__typename).toLowerCase() 
							temp_beatsByDriverType?.[driverTypeName]?.push(node)
							if(node.premiseConnections.length){
								temp_milestonBeatsByDriverType?.[driverTypeName]?.push(node)
							}
						})
						if(node.driverConnections.length ){
							temp_beatsByDriverType['withDriver'].push(node)
							if(node.premiseConnections.length){
								temp_milestonBeatsByDriverType["withDriver"].push(node)
							}
						} else{
							temp_beatsByDriverType['noDriver'].push(node)
							if(node.premiseConnections.length){
								temp_milestonBeatsByDriverType["noDriver"].push(node)
							}
						}
					}

					if (!['Character', 'Arc', 'Event', 'Theme', 'Setting'].includes(node.__typename) ){
						return
					}
					
					if(node.driverConnections.length >0){
						temp_driversWithABeat.push(node)
					}

					tempDriversByDesignation.total.push(node)
					
					if(node.coreElement === 'true'){
						tempDriversByDesignation.premise.push(node)	

					} 
					else if ( node.staticConnections?.find(conn => {
						return (
							graphData?.nodes[conn.destNode.id]?.coreElement === 'true' ||
							graphData?.nodes[conn.sourceNode.id]?.coreElement === 'true'
						)
					})) {

						tempDriversByDesignation.secondary.push(node)	
						if(node.driverConnections.find(conn => {
							return graphData?.nodes[conn.destNode.__typename==="Beat" ? conn.destNode.id : conn.sourceNode.id]?.premiseConnections.length

						})){
							temp_secondaryDriversWithMileStoneBeat.push(node)
						}
					}
					else {
						tempDriversByDesignation.minor.push(node)
						if(node.staticConnections.length){
							temp_minorDriversWithRelationship.push(node)
						}	
					}
			
			}
					
			)

			Object.keys(temp_beatsByDriverType).forEach(key => {
				temp_beatsByDriverType[key] = Array.from(new Set(temp_beatsByDriverType[key]))
				temp_milestonBeatsByDriverType[key] = Array.from(new Set(temp_milestonBeatsByDriverType[key]))
			})
			
			Object.values(relationships?.relations || []).forEach( rel => {

				temp_reltionshipsByType[rel.connectionType.toLowerCase()]?.push(rel)
				if(rel.connectionType === "Static" &&  rel.sharedBeats?.length ){
					temp_relationshipsWithABeat.push(rel)
				}


			})

			tempDriversByDesignation.premise = tempDriversByDesignation.premise.sort(
				(a, b) => (b.driverConnections?.length) - (a.driverConnections?.length),
			)
			tempDriversByDesignation.secondary = tempDriversByDesignation.secondary.sort(
				(a, b) => (b.driverConnections?.length) - (a.driverConnections?.length),
			)
			tempDriversByDesignation.minor = tempDriversByDesignation.minor.sort(
				(a, b) => (b.driverConnections?.length) - (a.driverConnections?.length),
			)
			tempDriversByDesignation.total = tempDriversByDesignation.total.sort(
				(a, b) => (b.driverConnections?.length) - (a.driverConnections?.length),
			)

			setPremiseDriverList(tempDriversByDesignation.premise)
			setDriversByDesignation(tempDriversByDesignation)



			let possiblePremiseConnections = []
			tempDriversByDesignation.premise.map((node, index) => {
				tempDriversByDesignation.premise.slice(index)?.forEach(n => {
					if (node.id !== n.id) {
						possiblePremiseConnections.push({
							sourceNode: node,
							destNode: n,
		
						})
					}
				})
			})
		

			const missingPremiseConnections =
				possiblePremiseConnections.filter(conn => {
					const foundEdge = relationships?.relations.find(edge => {
						return (
							(edge.sourceNode.id === conn.sourceNode.id && edge.destNode.id === conn.destNode.id) ||
							(edge.sourceNode.id === conn.destNode.id && edge.destNode.id === conn.sourceNode.id)
						)
					})
					
					return !foundEdge
				})|| []

			setMissingPremiseRelationships(missingPremiseConnections)
			
			const tempMilestoneBeatCount = graphData?.nodesByType["Premise"]?.[0]?.premiseConnections?.length
			setMilestoneBeatsCount(tempMilestoneBeatCount)
			const tempMissingMilestoneBeatsCount = Math.max(7 - tempMilestoneBeatCount,0)
			setMissingMilestoneBeatsCount(tempMissingMilestoneBeatsCount)
			setPremiseRingCompletionPct(Math.min(((possiblePremiseConnections?.length - missingPremiseConnections.length)+ Math.min(tempMilestoneBeatCount,7) )/ (7 + possiblePremiseConnections?.length),1 ) )

			setDriversWithBeat(temp_driversWithABeat)
			setRelationshipsWithABeat(temp_relationshipsWithABeat)
			setSecondaryDriversWithMileStoneBeat(temp_secondaryDriversWithMileStoneBeat)
			setMinorDriversWithRelationship(temp_minorDriversWithRelationship)
			setRelationshipsByType(temp_reltionshipsByType)

			setBeatsByDriverType(temp_beatsByDriverType)
			setMilestoneBeatsByDriverType(temp_milestonBeatsByDriverType)
		}
					

		


	}, [graphData?.nodes, relationships?.relations,loadingRelationship])



	return (
		<analyticsDataStateContext.Provider value={{...state,
			missingPremiseRelationships
			, premiseDriverList
			, premiseRingCompletionPct
			, milestoneBeatsCount
			, missingMilestoneBeatsCount
			, driversByDesignation
			, driversWithABeat
			, relationshipsWithABeat
			, secondaryDriversWithMileStoneBeat
			, minorDriversWithRelationship
			, relationshipsByType
			, beatsByDriverType
			, milestonBeatsByDriverType
			
			}}>
			<analyticsDataDispatchContext.Provider value={dispatch}>
				{children}
			</analyticsDataDispatchContext.Provider>
		</analyticsDataStateContext.Provider>
	)
}

export default AnalyticsDataProvider
