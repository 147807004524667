/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react'
import fullIcon from '@lynit/shared/src/images/full.svg'
import regIcon from '@lynit/shared/src/images/reg.svg'
import openIcon from '@lynit/shared/src/images/open.svg'
import activeFullIcon from '@lynit/shared/src/images/active-full-icon.svg'
import activeRegIcon from '@lynit/shared/src/images/active-reg-icon.svg'
import activeOpenIcon from '@lynit/shared/src/images/active-open-icon.svg'
import notesIcon from '@lynit/shared/src/images/notes-icon.svg'
import closeIcon from '@lynit/shared/src/images/close-icon.svg'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
	BeatDescription,
	BeatHeader,
	BeatLable,
	BeatSectionWrapper,
	BottomSectionContainer,
	CharacterImageWrapper,
	Connection,
	DescriptionArea,
	DescriptionFieldWrapper,
	DescriptionLable,
	DesignationLabel,
	Devider,
	DividerButton,
	DividerContainer,
	DividerLine,
	DriverDocBottomSection,
	DriverDocWrapper,
	DriverLabelArea,
	DriverName,
	DriverTypeLable,
	IconContainer,
	InnerView,
	LableContainer,
	LayoutStyleContainer,
	LayoutView,
	LeftHeaderData,
	MiddleSection,
	MiddleSectionDescription,
	NotesContainer,
	NotesCount,
	NotesIcon,
	NotesLabel,
	ScapBookLable,
	ScrapBookWrapper,
	SectionDeviderLine,
	DividerTooltip,
	DocInstructionsText,
	TitleSection,
} from './styles'
import { DeleteIcon, PremiseLine } from '../ListElement/styles'
import trashIcon from '@lynit/shared/src/images/IconDelete.svg'
import { useApolloClient } from '@apollo/client'

import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import orderBy from 'lodash/orderBy'
import tinycolor from 'tinycolor2'
import { useHistory } from 'react-router-dom'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {
	elementReordered,
	onSaveModal,
	setActiveConnections,
	setDeepModeElement,
	setDivingDeep,
	setLeftToggleTabs,
	setNoElementError,
	setRightToggleTabs,
	setServerError,
	setScrollPosition,
	setChapterViewExpanded,
	setVisualizationExpanded,
	setCurrentStory,
	setIsCatelogOpen,
	setElementToDelete,
} from '@lynit/shared/src/state/actions'
import CollapsiblePanel from '@lynit/shared/src/ui/CollapsiblePanel'
import { autoSaveStateContext } from '@lynit/shared/src/state/autoSaveProvider'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'
import {
	deleteCacheConnections,
	deleteCacheElement,
	updateCacheField,
	updateCacheNameElement,
} from '@lynit/shared/src/utils/apollo'
import { getNodeIcon, nodeTypeForId } from '@lynit/shared/src/utils/utils'
import {
	useReorderNodesMutation,
	useCreateNodeMutation,
	useUpdateNodeMutation,
} from '@lynit/shared/src/hooks'
import TextField from '@lynit/shared/src/ui/Textfield'

import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'

import { clickD3Node, selectD3Node, selectVizSVG } from '@lynit/story-outline/src/ui/viz/vizHelpers'
import openViewIcon from '@lynit/shared/src/images/openView.svg'
import openRegView from '@lynit/shared/src/images/openRegView.svg'
import DescriptionField from '@lynit/shared/src/ui/DescriptionField'
import { useMemo } from 'react'
import { useCallback } from 'react'
import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import { ConnectionCardStyle } from '../ChapterTab/styles'
import { MiddlePanel } from '@lynit/deep-mode-element/src/ui/DeepModeElement/styles'
import DragHelperModal from '@lynit/shared/src/ui/DragHelperModal'
import { useDroppable } from '@dnd-kit/core'
import { Tooltip } from '@mui/material'
import AiRecommendations from '@lynit/shared/src/ui/AIRecommendations/AIRecommendations'

const ThreePartDoc = ({
	id,
	BottomSectionInputComponent,
	MiddleSectionInputComponent,
	middleSectionDecriptionFields,
	docType,
	docInstructionsText = ''
}) => {
	const graphData = useContext(graphDataStateContext)
	const { user, refetchUser } = useContext(userStateContext)
	const [reorderNodes] = useReorderNodesMutation()
	const {
		state: {
			leftToggleTabs,
			rightToggleTabs,
			activeElement,
			activeConnections,
			deleteNode,
			scrollPosition,
			isChapterViewExpanded,
			currentStory,
			isCatelogOpen,
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)

	const { currentElement: currentDeepModeElement } = useContext(beatsDataStateContext)

	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const { createLog, isMobile, toastHandler } = useContext(systemStateContext)

	const client = useApolloClient()
	const history = useHistory()
	const serverErrorInterval = useRef()
	const scrollRef = useRef()
	const [elementName, setElementName] = useState()
	const [currentElement, setCurrentElement] = useState()
	const [onMouseOverView, setMouseOverView] = useState('open')
	const [designation, setDesignation] = useState('')
	const [view, setView] = useState('open')
	const [onMouseOverToggleIcon, setOnMouseOverToggleIcon] = useState(false)
	const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 768px)')
	const isSmallScreen = useMediaQuery('(max-width: 768px)')

	const { setNodeRef, isOver } = useDroppable({
		id: 'document-container',
		data: {
			id: 'document-container',
		},
	})

	const driverTypeDefinition = {
		Arc: `Move a situation forward in a specific order. Often a question is posed such as, "Will the main character achieve their goal despite a particular challenge?"`
		,Character: "The force that drives the story, and why the reader is emotionally engaged. Main characters also have an internal growth arc that progresses forward in a specific order."
		,Theme: `These are concepts that are consistent throughout the story, a theme of "Love conquers all", or symbolic imagery that repeats throughout the story.`
		,Setting: `This is the culture, society, and enviornment where the story takes place.`
		,Event: `Events are best used for capturing larger moments that take place in the story. It could be a festival, tournament, wedding, or an important backstory memory.`
		,Chapter: `A scene or combinations of scenes that progress the story in a significant way.`
	}

	
	
	useEffect(() => {
		if ((isMobile || isTablet || (isCatelogOpen) ) && graphData?.nodes[id]?.lastDocView === 'full') {
			setView('open')
			setMouseOverView('open')
		} else {
			setView(graphData?.nodes[id]?.lastDocView || 'open')
			setMouseOverView(graphData?.nodes[id]?.lastDocView || 'open')
		}
	}, [graphData?.nodes[id]?.lastDocView, isTablet, isMobile])

	let type = nodeTypeForId(id)
	const [updateElement] = useUpdateNodeMutation(type)

	useEffect(() => {
		const baseContainer = document.getElementById('baseContainer')
		const bottomDriverDoc = document.getElementById('bottom-driver-doc')

		if (view === 'full') {
			baseContainer?.setAttribute('style', 'position: relative;')
			bottomDriverDoc?.classList.add('full-view')
		} else {
			baseContainer?.setAttribute('style', 'position: unset;')
			bottomDriverDoc?.classList.remove('full-view')
		}
	}, [view])

	const handleDriverView = driverView => {
		setView(driverView)
		setMouseOverView(driverView)
		const updateData = {
			lastDocView: driverView,
			updatedAt: new Date().toISOString(),
		}
		updateCacheField(client, { id: id, __typename: nodeTypeForId(id) }, updateData, true)

		updateElement({
			variables: {
				id,
				input: {
					lastDocView: driverView,
					updatedAt: new Date().toISOString(),
				},
			},
		})
	}


	

	useEffect(() => {
		if (!id) {
			if (scrollRef.current) {
				scrollRef.current.scrollTop = scrollPosition
			}
		}
	}, [id])

	useEffect(() => {
		if (id && docType === 'Driver') {
			const isPremiseDriver = graphData?.nodes[id]?.coreElement === "true"
			let doesSecondary = graphData?.nodes[id]?.staticConnections?.filter(conn => {
				return (
					graphData?.nodes[conn.destNode.id]?.coreElement === 'true' ||
					graphData?.nodes[conn.sourceNode.id]?.coreElement === 'true'
				)
			})

			setDesignation(
				isPremiseDriver
					? {label:'Premise', toolTip: "A Driver within the Premise"}
					: doesSecondary?.length
					? {label:'Secondary', toolTip: "Has relationship to Premise Driver"}
					: {label:'Minor', toolTip: "No relationships to a Premise Driver"},
			)
		}
	}, [graphData?.allNodes?.nodes, id])

	const RenderDescriptionFields = useCallback(
		({ descriptionFields }) => {
			return descriptionFields?.map((fields, index) => {
				return (
					<DescriptionArea key={`${fields.slug}${index}`}>
						<DescriptionLable>{fields.label}</DescriptionLable>
						<DescriptionFieldWrapper>
							{fields.aiRecommendations ? (
								<AiRecommendations
									key={`${id}-${fields.slug}`}
									coreElement={fields.coreElement}>
									<DescriptionField
										id={id}
										key={`${id}-${fields.slug}`}
										fieldName={fields.slug}
										type={nodeTypeForId(id)}
										createLog={createLog}
										isArcConnection={false}
										isElement={true}
										placeholder={fields.placeholder}
									/>
								</AiRecommendations>
							) : (
								<DescriptionField
									id={id}
									key={`${id}-${fields.slug}`}
									fieldName={fields.slug}
									type={nodeTypeForId(id)}
									createLog={createLog}
									isArcConnection={false}
									isElement={true}
									placeholder={fields.placeholder}
								/>
							)}
						</DescriptionFieldWrapper>
					</DescriptionArea>
				)
			})
		},
		[id]
	)

	const lightenAmount = {
		Chapter: 91,
		Theme: 24,
		Setting: 62,
		Event: 39,
		Arc: 41,
		Character: 39
	}
	const backgroundColor = ['Chapter','Driver'].includes(docType) ? tinycolor(nodeColors[type]).lighten(lightenAmount[type]||91).toHexString() : '#FFFFFF'
	

	return (
		<div
			key={id}
			style={
				{
					// padding: `0 15px 15px 10px`,
					background: backgroundColor,
				}
			}
			ref = {setNodeRef } 
		>
			<DriverDocWrapper key={`${id}-tpdTop`} color={ backgroundColor} view={view}>
				<TitleSection>
					<LeftHeaderData>
						{["Driver","Chapter"].includes(docType) && (
							<DeleteIcon
								src={trashIcon}
								alt="Delete Button"
								onClick={e => {								
									e.stopPropagation()
									let name = graphData?.nodes[id]?.name
									let type = graphData?.nodes[id]?.__typename
									sharedDispatch(
										setElementToDelete({
											isActive: true,
											type,
											id,
											nodeName: name,
										}),
									)
									createLog(
										'Element Deletion Attempted',
										`{"workflowStep":${1},"elementType":"${type}"}`,
										'ThreePartDoc',
										'Element Deletion',
									)
									sharedDispatch(setIsCatelogOpen(false))
								}}
							/>
						)}
						<CharacterImageWrapper>
							<IconContainer isSelected={true} color={type}>
								<img src={getNodeIcon(type)} />
							</IconContainer>
						</CharacterImageWrapper>
						<DriverLabelArea>
							<DriverName>
								{docType === 'Chapter' && <>Ch:</>}
								{['Chapter'].includes(docType) && (
									<TextField
										id={graphData?.nodes[id]?.id}
										size={'90%'}
										type={graphData?.nodes[id]?.__typename || 'Character'}
										content={graphData?.nodes[id]?.number}
										placeholder={`0`}
										isNumber={true}
										docType={docType}
									/>
								)}

								{['Driver', 'Chapter'].includes(docType) ? (
									<TextField
										id={graphData?.nodes[id]?.id}
										size={'90%'}
										type={graphData?.nodes[id]?.__typename || 'Character'}
										content={graphData?.nodes[id]?.name}
										placeholder={`New ${graphData.nodes[id]?.__typename}`}
										isNumber={false}
										docType={docType}
									/>
								) : (
									docType
								)}
							</DriverName>
							<LableContainer>
									<>
										{docType === 'Driver' && ( 
										<Tooltip
											title={designation.toolTip}
											placement="bottom"
											arrow
											classes={{
												tooltip:  {
													background: '#55534F',
												},
												arrow: {
													color: '#55534F',
												},
											}}
										>  
											<DesignationLabel
											type={designation.label}
											onMouseOver={() => {
												createLog(
													`Hover Over Driver Designation`,
													{docType, view:'open', designation: designation.label},
													'ThreePartDoc',
													'',
												)
											}}
											>{designation.label}</DesignationLabel>
										</Tooltip>)}
										{["Chapter","Driver"].includes(docType) && (
										<Tooltip
											title={driverTypeDefinition[graphData.nodes[id]?.__typename]}
											placement="bottom"
											arrow
											classes={{
												tooltip:  {
													background: '#55534F',
												},
												arrow: {
													color: '#55534F',
												},
											}}
										> 
											<DriverTypeLable 
												color={graphData.nodes[id]?.__typename}
												onMouseOver={() => {
													createLog(
														`Hover Over Driver Type`,
														{docType, view:'open', type: graphData.nodes[id]?.__typename},
														'ThreePartDoc',
														'',
													)
												}}
												>
													{graphData.nodes[id]?.__typename }
											</DriverTypeLable>
										</Tooltip> )}
									</>
								
								{['Driver', 'Chapter'].includes(docType) && false && (
									<NotesContainer>
										<NotesCount>{graphData.nodes[id]?.noteConnections?.length || 0}</NotesCount>
										<NotesIcon src={notesIcon} alt="notes-icon" />
										<NotesLabel>Related Notes</NotesLabel>
									</NotesContainer>
								)}
							</LableContainer>
						</DriverLabelArea>
					</LeftHeaderData>
					<LayoutView>
						{!['Premise', 'Outlining', 'Writing'].includes(docType) && (
							<LayoutStyleContainer
								isCloseButton={true}
								onClick={() => {
									createLog(
										`Close Doc`,
										{docType},
										'ThreePartDoc',
										'',
									)
									sharedDispatch(setIsCatelogOpen(false))
									sharedDispatch(
										setActiveConnections({
											isActive: true,
											elementId: null,
											elementType: null,
											docType: activeConnections.appView,
											appView: activeConnections.appView,
										}),
									)
								}}
							>
								<img src={closeIcon} alt="close-icon" />
								<p>Close</p>
							</LayoutStyleContainer>
						)}
						<LayoutStyleContainer
							isActive={view === 'collapsed'}
							onClick={() => {
								createLog(
									`Change Doc View`,
									{docType, view:'collapsed'},
									'ThreePartDoc',
									'',
								)
								handleDriverView('collapsed')
							}}
							onMouseEnter={() => {
								setMouseOverView('collapsed')
							}}
							onMouseLeave={() => {
								setMouseOverView(null)
							}}
						>
							<img
								src={view === 'collapsed' || onMouseOverView === 'collapsed' ? activeRegIcon : regIcon}
								alt="reg-icon"
							/>
							<p>Collapsed</p>
						</LayoutStyleContainer>
						<LayoutStyleContainer
							isActive={view === 'open'}
							onClick={() => {
								createLog(
									`Change Doc View`,
									{docType, view:'open'},
									'ThreePartDoc',
									'',
								)
								handleDriverView('open')
							}}
							onMouseEnter={() => {
								setMouseOverView('open')
							}}
							onMouseLeave={() => {
								setMouseOverView(null)
							}}
						>
							<img
								src={view === 'open' || onMouseOverView === 'open' ? activeOpenIcon : openIcon}
								alt="open-icon"
							/>
							<p>Open</p>
						</LayoutStyleContainer>
						<LayoutStyleContainer
							isActive={view === 'full'}
							hide={isSmallScreen}
							onClick={() => {
								createLog(
									`Change Doc View`,
									{docType, view:'full'},
									'ThreePartDoc',
									'',
								)
								sharedDispatch(setIsCatelogOpen(false))
								handleDriverView('full')
							}}
							onMouseEnter={() => {
								setMouseOverView('full')
							}}
							onMouseLeave={() => {
								setMouseOverView(null)
							}}
						>
							<img
								src={view === 'full' || onMouseOverView === 'full' ? activeFullIcon : fullIcon}
								alt="full-icon"
							/>
							<p>Full</p>
						</LayoutStyleContainer>
					</LayoutView>
				</TitleSection>
				
				{docInstructionsText && (<DocInstructionsText>
					{docInstructionsText}
				</DocInstructionsText>)}
			</DriverDocWrapper>
			{view !== 'collapsed' && (
				<MiddleSection id = {`${id}-tpdMiddle`} key={`${id}-tpdMiddle`} color={ backgroundColor} isVisible={view !== 'collapsed'} view={view}>
				{/* <DragHelperModal
				description="Drag Beats to Chapters"
				blurOnly={true}
				blur={true && type !== "Chapter"}
				target={`${id}-tpdMiddle`}
				isOpen={isOver && type !== "Chapter"}
				/> */}
					{middleSectionDecriptionFields.length > 0 && (<MiddleSectionDescription>
						{<RenderDescriptionFields descriptionFields={middleSectionDecriptionFields} />}
					</MiddleSectionDescription>)}
					<MiddleSectionInputComponent />
				</MiddleSection>
			)}
			<BottomSectionContainer key={`${id}-tpdBottom`} color={ backgroundColor} id="bottom-driver-doc" view={view} isReg={view === 'collapsed'}>
				
				<Connection isVisible={view !== 'full'}>
					<SectionDeviderLine isReg={view === 'collapsed'}>
						<InnerView>
							<img
								src={view === 'open' ? openRegView : openViewIcon}
								alt="open-svg-view"
								onClick={() => {
									createLog(
										`Clicked Middle Section Collapse/Open Button`,
										{docType, view:'open', type: graphData.nodes[id]?.__typename, button: view === 'collapsed' ? `Open View` : `Collapse View`},
										'ThreePartDoc',
										'',
									)
									view === 'open' ? handleDriverView('collapsed') : handleDriverView('open')
								}}
								onMouseEnter={() => {
									setOnMouseOverToggleIcon(true)
								}}
								onMouseLeave={() => {
									setOnMouseOverToggleIcon(false)
								}}
							/>{' '}
							<DividerTooltip isVisible={onMouseOverToggleIcon}>
								
							</DividerTooltip>
						</InnerView>
					</SectionDeviderLine>
				</Connection>
				<DriverDocBottomSection>
					<BeatSectionWrapper view={view} ref={scrollRef}>
						{id && <BottomSectionInputComponent />}
					</BeatSectionWrapper>
				</DriverDocBottomSection>
			</BottomSectionContainer>
		</div>
	)
}

export default React.memo(ThreePartDoc)
