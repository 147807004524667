import React, { useEffect, useRef } from 'react'
import {
	AiRecommendationsCount,
	AiRecommendationsWrapper,
	DriverAction,
	DriverField,
	DriverList,
	DriverName,
	Header,
	ListArea,
	SignIcon,
	Title,
	DescriptionWrapper,
	MenuItemContainer,
	MenuListWrapper,
	CrossIcon,
	Dropdown,
	DropdownContainer,
} from './styles'
import hash from 'object-hash'
import Popover from '@mui/material/Popover'
import { makeStyles } from '@mui/styles'
import GlareIcon from '../../images/glare.svg'
import { IconContainer } from '@lynit/layout/src/ui/ListElement/styles'
import { updateNodeMutations } from '@lynit/shared/src/data'
import { useState } from 'react'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { useContext } from 'react'
import { useCreateNodeMutation, useEditStoryMutation } from '@lynit/shared/src/hooks'
import { useApolloClient } from '@apollo/client'
import { createNodeId, getNodeIcon, nodeTypeForId, vizTitleLengthFormat } from '../../utils/utils'
import { createCacheElement } from '../../utils/apollo'
import { getAiRecommendationFromText } from '../../utils/lynitCoachAnalytics'
import { Divider, Menu, MenuItem, Tooltip } from '@mui/material'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { setCurrentStory } from '@lynit/shared/src/state/actions'

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		maxWidth:'250px',
		padding: '10px',
	},
}))
const AiRecommendations = ({ children , coreElement}) => {
	const [aiRecommendations, setAiRecommendations] = useState({})
	const [filteredAiRecommendations, setFilteredAiRecommendations] = useState([])
	const [aiDriversOnHover, setAiDriversOnHover] = useState([])
	const [active, setActive] = useState()
	const [driverTypeAnchorEl, setDriverTypeAnchorEl] = useState(null)
	const [selectedDriver, setSelectedDriver] = useState(null)
	const {createLog} = useContext(systemStateContext)
	const {
		state: {
			currentStory,
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)
	const [editStory] = useEditStoryMutation({ ignoreResults: true })
	
	let driverType = ['Character', 'Arc', 'Event', 'Theme', 'Setting']

	const setConnectionModal = () => {
		setActive(false)
		setTimeout(() => {
			setAiDriversOnHover([])
		}, 300)
	}

	const graphData = useContext(graphDataStateContext)

	const classes = useStyles()
	const mutations = {}

	const currentDriverNameHash = hash(
		new Set(
			Object.values(graphData?.allNodes.nodes)
				?.map(node => node.name),
		),
	)

	useEffect(()=> {
		let tempFiltered =[]
		if(aiRecommendations?.drivers?.length){
			
			
			tempFiltered = aiRecommendations.drivers.filter(driver => {
				const isNewDriverSuggestion = !graphData.allNodes.nodes?.some(node => node?.name?.toLowerCase() === driver?.name?.toLowerCase()) && !currentStory?.aiDriverNamesToIgnore?.some(ignoreDriver => JSON.parse(ignoreDriver)?.name?.toLowerCase() === driver?.name?.toLowerCase())
				
				return isNewDriverSuggestion
			})

			createLog(
				`Lynit Coach Recommendation Notification Shown`,
				{recommendedDriverCount:tempFiltered.length, requestId:aiRecommendations.id, fieldName:aiRecommendations.fieldName, driverId:aiRecommendations.driverId, driverType:nodeTypeForId(aiRecommendations?.driverId)},
				'AIRecommendations',
				'Lynit Coach Recommendation',
			)
			
			
		} 
		setFilteredAiRecommendations(tempFiltered)

		if(tempFiltered.length === 0 ){
			setActive(false)
		}

	},[aiRecommendations,currentDriverNameHash, currentStory?.aiDriverNamesToIgnore ])

	Object.keys(updateNodeMutations).forEach(key => {
		const [createMutation] = useCreateNodeMutation(key, { ignoreResults: true })
		mutations[key] = createMutation
	})

	// const filterDriver = elementName => {
	// 	setAiRecommendations(prev => prev.filter(driver => driver?.name !== elementName))
	// 	setAiDriversOnHover(prev => prev.filter(driver => driver?.name !== elementName))
	// }

	const client = useApolloClient()

	const createStoryElement = async elList => {
		const optimisticResponses = []
		for (const el of elList) {

			createLog(
				`Element Creation Attempted`,
				{workflowStep:2, elementType:el.driverType, source:"Lynit Coach"},
				'AIRecommendations',
				'Element Creation',
			)

			createLog(
				`Element Creation Confirmed`,
				{workflowStep:2, elementType:el.driverType, source:"Lynit Coach"},
				'AIRecommendations',
				'Element Creation',
			)
			
			const tempObj = {
				id: createNodeId(el.driverType),
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
				order: String(graphData?.nodesByType[el.driverType]?.length),
				__typename: el.driverType,
				name: el.name,
				description: "System Generated Summary: " + el.description,
				contents: '',
				title: '',
				number: '0',
				beatConnections: [],
				driverConnections: [],
				childConnections: [],
				staticConnections: [],
				noteConnections: [],
				premiseConnections: [],
				xCoordinate: null,
				yCoordinate: null,
				vizLocSaved: null,
				firstBeat: '',
				coreElement,
				storyText: '',
				wordCount: '0',
				innerConflict: '',
				externalConflict: '',
				type: '',
				goalsAndNeeds: '',
				centralConflict: '',
				culture: '',
				society: '',
				magicTech: '',
				environment: '',
				variations: '',
				moralArgument: '',
				motifs: '',
				lastDocView: '',
				lastOpenedDate: '',
				templateText: '',
				milestone: false,
				nameAliases:[],
				aiRecommendations:'',
			}
			const optimisticResponse = {}
			optimisticResponse[`create${el.driverType}`] = tempObj
			let node = {
				id: tempObj.id,
				name: tempObj.name,
				description: tempObj.description,
				order: tempObj.order,
				coreElement: tempObj.coreElement,
			}
			optimisticResponses.push(optimisticResponse)
			if (mutations[el.driverType]) {
				mutations[el.driverType]({
					node,
					optimisticResponse,
					tempObj,
					hasOrder: false,
				})
				
			}
		}

		await createCacheElement(client, optimisticResponses, false, true, true)
	}

	const handleFastCreation = async () => {
		setAiRecommendations([])
		setAiDriversOnHover([])
		setActive(false)
		createStoryElement(filteredAiRecommendations)

		createLog(
			`Lynit Coach Recommendation Fast Create All Clicked`,
			{recommendedDriverCount:filteredAiRecommendations.length, requestId:aiRecommendations.id, fieldName:aiRecommendations.fieldName, driverId:aiRecommendations.driverId, driverType:nodeTypeForId(aiRecommendations.driverId)},
			'AIRecommendations',
			'Lynit Coach Recommendation',
		)

		const editor = document?.querySelector('.ql-editor')
		if (!editor) return

		// Clear existing highlights
		editor.querySelectorAll('span.highlighted').forEach(span => {
			const parent = span.parentNode
			parent.replaceChild(document.createTextNode(span.textContent), span)
			parent.normalize() // Merge text nodes to avoid fragmenting
		})
	}

	const mouseEnterTimeout = useRef(null)
	const mouseLeaveTimeout = useRef(null)

	const handleMouseEnter = e => {
		if (mouseLeaveTimeout.current) {
			clearTimeout(mouseLeaveTimeout.current)
		}

		mouseEnterTimeout.current = setTimeout(() => {
			setActive(e.target)
			let aiRecommendationData = getAiRecommendationFromText(e.target.innerText).filter(ai => {
				return !graphData.allNodes.nodes.some(node => node?.name === ai?.name)
			})
			setAiDriversOnHover(aiRecommendationData)
		}, 0)
	}

	const performHighlight = async (quillRef, quill) => {
		const escapeRegExp = string => {
			return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
		}

		const highlightWords = (quillRef, wordsToHighlight) => {
			if (wordsToHighlight.length) {
				const editor = quillRef.current?.querySelector('.ql-editor')
				if (!editor) return

				// Clear existing highlights
				editor.querySelectorAll('span.highlighted').forEach(span => {
					const parent = span.parentNode
					parent.replaceChild(document.createTextNode(span.textContent), span)
					parent.normalize() // Merge text nodes to avoid fragmenting
				})

				// Apply new highlights
				wordsToHighlight.forEach(wordObj => {
					const escapedWord = escapeRegExp(wordObj.name)
					const regex = new RegExp(`\\b(${escapedWord})\\b`, 'gi')
					editor.innerHTML = editor.innerHTML.replace(
						regex,
						`<span class="highlighted" style="background-color: #FFC300; cursor: pointer;">$1</span>`,
					)
				})

				// Attach mouse event listeners Fto new highlights
				const highlightedWords = editor.querySelectorAll('span.highlighted')
				highlightedWords.forEach(span => {
					span.addEventListener('mouseenter', handleMouseEnter)
				})
			}
		}
		const applyTextHighlights = () => {
			const editor = quillRef.current?.querySelector('.ql-editor')
			if (!editor) return

			// Remove existing highlights before reapplying
			editor.querySelectorAll('span.highlighted').forEach(highlightedElement => {
				const parentElement = highlightedElement.parentNode
				parentElement.replaceChild(
					document.createTextNode(highlightedElement.textContent),
					highlightedElement,
				)
				parentElement.normalize() // Merge text nodes to prevent fragmenting
			})

			// Apply highlights based on `aiRecommendations`
			highlightWords(
				quillRef,
				filteredAiRecommendations?.map(ai => ({ name: ai.name })),
			)
		}

		applyTextHighlights()
	}

	let extraProp = {
		aiRecommendated: true,
		setAiRecommendations: setAiRecommendations,
		aiRecommendations: filteredAiRecommendations,
		performHighlight: performHighlight,
		isDroupdownOpen: aiDriversOnHover.length ? Boolean(active) : false,
	}

	const childrenWithExtraProps = React.Children.map(children, child => {
		return React.cloneElement(child, extraProp)
	})

	return (
		<DescriptionWrapper
			style={{
				position: 'relative',
			}}
		>
			{aiRecommendations.loading && (<AiRecommendationsCount recLoading={true}></AiRecommendationsCount>)}
			{filteredAiRecommendations.length > 0 && !aiRecommendations.loading && (<AiRecommendationsCount
				onClick={e => {
					setActive(e.currentTarget)

					createLog(
						`Lynit Coach Recommendation Notification Opened`,
						{recommendedDriverCount:filteredAiRecommendations.length, requestId:aiRecommendations.id, fieldName:aiRecommendations.fieldName, driverId:aiRecommendations.driverId, driverType:nodeTypeForId(aiRecommendations.driverId)},
						'AIRecommendations',
						'Lynit Coach Recommendation',
					)

				}}
			>
				{filteredAiRecommendations.length}
			</AiRecommendationsCount>)}
			{childrenWithExtraProps}
			<Popover
				id="connection-modal"
				anchorEl={active}
				open={Boolean(active) }
				onClose={() => setConnectionModal(false)}
				classes={{ paper: classes.root }}
				style={{
					zIndex: '999',
				}}
			>
				<AiRecommendationsWrapper>
					<Header>
						<img src={GlareIcon} alt="glare-icon" />
						<p style={{
								fontSize: '11px',
								paddingLeft:'5px',
								margin: '0px'
						}}>
							We identified some potential Drivers you might want to quickly create.
						</p>
					</Header>
					<Title onClick={handleFastCreation}>Fast Create All</Title>
					<ListArea>
						{(aiDriversOnHover.length ? aiDriversOnHover : filteredAiRecommendations).map(
							(aiRecommendation, i) => (
								<DriverList key={`${i}_${aiRecommendation.name}`}>
									<IconContainer
										isSelected={true}
										color={aiRecommendation.driverType}
										style={{
											cursor: 'pointer',
										}}
										onClick={e => {
											setSelectedDriver(aiRecommendation)
											setDriverTypeAnchorEl(e.currentTarget)
										}}
									>
										<img src={getNodeIcon(aiRecommendation.driverType)} />
										<div
											style={{
												position: 'absolute',
												right: '-16%',
												bottom: '1%',
											}}
										>
											<DropdownContainer>
												<Dropdown color={aiRecommendation.driverType} />
											</DropdownContainer>
										</div>
									</IconContainer>{' '}
									<DriverField>
										<DriverName>{vizTitleLengthFormat(aiRecommendation.name, 30)}</DriverName>
										<DriverAction>
											<Tooltip
												title={`Add ${aiRecommendation.name}`}
												placement="top"
												arrow
												classes={{
													tooltip: {
														background: '#55534F',
													},
													arrow: {
														color: '#55534F',
													},
												}}
											>
												<SignIcon
													aria-controls="connection-modal"
													aria-haspopup="true"
													onClick={() => {
														let node = [
															aiRecommendation,
														]
														createStoryElement(node)
														
														// (filteredAiRecommendations.length === 1) && setActive(false)
														//filterDriver(aiRecommendation.name)
														createLog(
															`Lynit Coach Recommendation Fast Create Driver Click`,
															{newDriverType: aiRecommendation.driverType ,requestId:aiRecommendations.id, fieldName:aiRecommendations.fieldName, driverId:aiRecommendations.driverId, driverType:nodeTypeForId(aiRecommendations.driverId)},
															'AIRecommendations',
															'Lynit Coach Recommendation',
														)
													}}
												>
													+
												</SignIcon>
											</Tooltip>

											<Tooltip
												title={`Remove ${aiRecommendation.name}`}
												placement="top"
												arrow
												classes={{
													tooltip: {
														background: '#55534F',
													},
													arrow: {
														color: '#55534F',
													},
												}}
											>
												<SignIcon
													aria-controls="connection-modal"
													aria-haspopup="true"
													cross
													onClick={() => {
														const input= {aiDriverNamesToIgnore:(currentStory.aiDriverNamesToIgnore||[]).concat([JSON.stringify(aiRecommendation)])
															
														}
														
														sharedDispatch(setCurrentStory({ ...currentStory, ...input }))
														editStory({
															variables: {
																id: currentStory?.id,
																input,
															},
														})
														//filterDriver(aiRecommendation.name)
														//console.log("filteredAiRecommendations.length",filteredAiRecommendations.length)
														//(filteredAiRecommendations.length === 1) && setActive(false)
														//Add this driver name to the story level ignore list. that way we know to not show it again.
														createLog(
															`Lynit Coach Recommendation Ignore Driver Recommendation Clicked`,
															{newDriverType: aiRecommendation.driverType ,requestId:aiRecommendations.id, fieldName:aiRecommendations.fieldName, driverId:aiRecommendations.driverId, driverType:nodeTypeForId(aiRecommendations.driverId)},
															'AIRecommendations',
															'Lynit Coach Recommendation',
														)
													}}
												>
													<CrossIcon>x</CrossIcon>
												</SignIcon>
											</Tooltip>
										</DriverAction>
									</DriverField>
								</DriverList>
							),
						)}
					</ListArea>
					<Menu
						anchorEl={driverTypeAnchorEl}
						id="account-menu"
						open={Boolean(driverTypeAnchorEl)}
						onClose={() => {
							setDriverTypeAnchorEl(null)
						}}
						onClick={() => {}}
						sx={{
							padding: '10px',
						}}
						slotProps={{
							paper: {
								elevation: 0,
								sx: {
									overflow: 'visible',
									padding: '10px',
									width:'200px',
									filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									mt: 1.5,
									'& .MuiAvatar-root': {
										width: 32,
										height: 32,
										ml: -0.5,
										mr: 1,
									},
									'&::before': {
										content: '""',
										display: 'block',
										position: 'absolute',
										top: 0,
										right: 14,
										width: 10,
										height: 10,
										bgcolor: 'background.paper',
										transform: 'translateY(-50%) rotate(45deg)',
										zIndex: 0,
									},
								},
							},
						}}
						transformOrigin={{ horizontal: 'left', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
					>
						<MenuListWrapper>
							{driverType
								.filter(type => type !== selectedDriver?.driverType)
								.map((type, i) => {
									return (
										<MenuItemContainer
											key={`${type}_${i}`}
											onClick={() => {
												// setAiDriversOnHover(prev =>
												// 	prev.map((aiDriverType, i) => {
												// 		if (selectedDriver.name === aiDriverType.name) {
												// 			return {
												// 				...aiDriverType,
												// 				driverType: type,
												// 			}
												// 		}
												// 		return aiDriverType
												// 	}),
												// )
												setFilteredAiRecommendations(prev =>
													prev.map((aiDriverType, i) => {
														if (selectedDriver.name === aiDriverType.name) {
															return {
																...aiDriverType,
																driverType: type,
															}
														}
														return aiDriverType
													}),
												)
												setDriverTypeAnchorEl(null)
											}}
										>
											<IconContainer isSelected={true} color={type}>
												<img src={getNodeIcon(type)} />
											</IconContainer>
											<DriverName>{type}</DriverName>
										</MenuItemContainer>
									)
								})}
						</MenuListWrapper>
					</Menu>
				</AiRecommendationsWrapper>
			</Popover>
		</DescriptionWrapper>
	)
}
export default AiRecommendations
