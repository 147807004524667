import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react'
import { useCreateEventlogMutation, useGetUserQuery, useGetVersionQuery } from '../hooks'
import LogRocket from 'logrocket'
import ReactGA from 'react-ga4'
import { useContext } from 'react'
import { sharedContext } from '.'
import { sharedReducer } from './sharedReducer'
import {
	isFirefox,
	isEdge,
	isChrome,
	isSafari,
	isMobile,
	isAndroid,
	isIOS,
	isWinPhone
} from 'react-device-detect'
import axios from 'axios'
import {initializeLogRocket} from '../utils/logRocketSetup'
import { toast } from 'react-toastify'
import { setIsDifferentVersion, setIsMobile } from './actions'
import { useMediaQuery } from '@mui/material'
import { isJsonString } from '../utils/utils'



export const systemStateContext = createContext()
export const systemDispatchContext = createContext()

const systemRelatedState = {
    isLoggedIn: !!localStorage.getItem('token'),
    wasOffline: false,
	isOnline: window.navigator.onLine,
    isDifferentVersion: false,
    isNewUser: false,
    isUserSubscribed: false,
	isMobile,
	isSummaryCardVisible: isMobile? false: false
}

export const systemRelatedData = payload => {
	return {
		type: 'SYSTEM_DATA',
		payload,
	}
}

export const systemRelatedReducer = (state, action) => {
	switch (action.type) {
		case 'SYSTEM_DATA':
			return action.payload 
		default:
			return state
	}
}

export const SystemProvider = ({ children }) => {
	const [state, dispatch] = useReducer(sharedReducer, systemRelatedState)
    const [browser, setBrowser] = useState('')
	const [device, setDevice] = useState('')
	const [ipAddress, setIpAddress] = useState('')
	const [isOnline, setIsOnline] = useState(true)
	const [wasOffline, setWasOffline] = useState(false)
    const story = useContext(sharedContext)
	const { data: userData } = useGetUserQuery({ fetchPolicy: 'cache-first' })
	const {
		loading: loadingVersion,
		data: dataVersion,
		refetch: refetchVersion
	} = useGetVersionQuery()
	
	const [createEventLog] = useCreateEventlogMutation({
		ignoreResults: true,
		fetchPolicy: 'no-cache'
	})

	let logrocketSessionURL
	LogRocket.getSessionURL(sessionURL => {
		logrocketSessionURL = sessionURL
	})

	// const {
	// 	state: {
	// 		activeConnections,
	// 	},
	// } = useContext(sharedContext)

	useEffect(() => {
		if (dataVersion && !loadingVersion) {
			//user logged in
			if (!localStorage.version && dataVersion.version) {
				localStorage.setItem('version', dataVersion.version)
			} // user is in latest version
			if (localStorage.version === dataVersion.version) {
				dispatch(setIsDifferentVersion(false)) 
			} //user is in old version
			if (localStorage.version !== dataVersion.version && dataVersion.version) {
				localStorage.setItem('version', dataVersion.version)
				dispatch(setIsDifferentVersion(true))
				setTimeout(() => window.location.reload(), 5000)
			}
		}
	}, [dataVersion])

	const sharedContextState = useContext(sharedContext)?.state
	const {activeConnections, isCatelogOpen} = sharedContextState


	//NETWORK MGMT LOGIC
	const updateNetwork = () => {
		if (window.navigator.onLine) {
			setIsOnline(window.navigator.onLine)
			setTimeout(() => (setWasOffline(false)), 5000)
		}
		if (!window.navigator.onLine) {
			setWasOffline(true)
			setIsOnline(false)
		}
	}

	useEffect(() => {
		window?.addEventListener('offline', updateNetwork)
		window?.addEventListener('online', updateNetwork)
		return () => {
			window.removeEventListener('offline', updateNetwork)
			window.removeEventListener('online', updateNetwork)
		}
	}, [])
	
	const isMobileUpdated = useMediaQuery('(max-width: 600px)')
	useEffect(()=>{
		dispatch(setIsMobile(isMobileUpdated))

	},[isMobileUpdated])
	useEffect(async () => {
		try{
			initializeLogRocket()
		}
		catch(e){
			createLog(
				`Initialize Screen Recording Failed`,
				`{"errorMessage":"${e.message}"}`,
				``,
				``,
			)
		}
		
		let res
		try{
			res = await axios.get('https://api.ipify.org?format=json')
			setIpAddress(res?.data?.ip)

		} catch {
			res = await axios.get('https://api.ipify.org?format=json')
			setIpAddress(res?.data?.ip)
		}
		
	},[])

	useEffect(() => {
		//reactour isFirstLogin logic to decide if render tour or not
		if (isFirefox) {
			setBrowser('Firefox')
		}
		if (isEdge) {
			setBrowser('Edge')
		}
		if (isChrome) {
			setBrowser('Chrome')
		}
		if (isSafari) {
			setBrowser('Safari')
		}
		if (isMobile) {
			
			setDevice('Mobile')
		}
		if (isAndroid) {
			setDevice('Android')
		}
		if (isIOS) {
			setDevice('IOS')
		}
		if (isWinPhone) {
			setDevice('WindowsPhone')
		} else {
			setDevice('Computer')
		}
	}, [userData?.user?.isFirstLogin, window.innerWidth])
	
    const createLog = useCallback(
		async (action, actionDetail, component, workflow) => {
			let details = {}
			if(isJsonString(actionDetail)){
				details = JSON.parse(actionDetail)
			} else{
				details = actionDetail
			}

			if(userData?.userSummary?.staticConnectionCount === 0 && action==="Connection Creation Attempted" && details?.connectionType === "Static"){

				ReactGA.initialize([
					{
						trackingId: 'G-E0KD23FR96',
						gaOptions:{user_id: userData?.id}
					},
					{
						trackingId: 'UA-172831121-1',
						gaOptions:{user_id: userData?.id}
					}
				])
	
				ReactGA.event({
					category: 'Relationship Creation Attempted',
					action: 'First Relationship Creation Attempted',
					label: 'Create Relationship Attempted'
				})
			}

			
			
			details['logrocketSessionURL'] = logrocketSessionURL
			details['app'] = 'lynit_frontend'
			details['isDeepMode'] = !!state.deepModeElement
			details['ip_address'] = ipAddress
			details['writingStage'] = activeConnections?.appView
			details['docType'] = activeConnections?.docType
			details['docOpened'] = activeConnections?.isActive
			details['isCatalogOpened'] = isCatelogOpen
			createEventLog({
				variables: {
					input: {
						name: `${action}`,
						details: JSON.stringify(details),
						page: '/',
						component: `${component}`,
						workflow: `${workflow}`,
						browser,
						geo: '',
						device,
						createdAt: Date.now(),
						app_version: localStorage.version
					}
				},
				context: {
					headers: {
						ipAddress:ipAddress
					}
				}
			})
		},
		[localStorage.version,ipAddress, activeConnections, isCatelogOpen]
	)




const toastHandler = (state, message, content, component, workflow) => {
	//Error handling
	if (typeof state !== 'string') {
		throw new Error(
			`TypeError: Expected a string as a value for the "state" parameter, instead got ${typeof state}`
		)
	}
	// Cases handling for different states of "state" parameter
	if (state === 'success') {
		toast.success(message)
	} else if (state === 'warning') {
		toast.warning(message)
	} else if (state === 'error') {
		toast.error(message)
	} else if (state === 'dark') {
		toast.dark(content, {
			onClose: props => props.close(),
			position: 'bottom-right',
			autoClose: props => props.autoClose,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		})
	} else if (state === 'deepDark') {
		toast.dark(content, {
			position: 'top-left',
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		})
	} else if (state === 'dark1') {
		toast.dark(content, {
			onClose: props => props.close(),
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			position: 'bottom-center'
		})
	} else if (state === 'dark2') {
		toast.dark(content, {
			onClose: props => props.close(),
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		})
	} else {
		throw new Error(
			'InputError: Expected either "success", "warning" or "error" as values for the "state" parameter'
		)
	}

	createLog(
		`ToastHandler Error Message`,
		`{"errorMessage":"${message}"}`,
		`${component}`,
		`${workflow}`,
	)
}


	return (
		<systemStateContext.Provider value={{...state, createLog,ipAddress ,toastHandler, isOnline, wasOffline}}>
			<systemDispatchContext.Provider value={dispatch}>
				{children}
			</systemDispatchContext.Provider>
		</systemStateContext.Provider>
	)
}

export default SystemProvider